import React from "react";
import FunctionPageLayout from "../components/functionPageLayout";
import SEO from "../components/seo";
import BenefitBlockWrapper from "../components/BenefitBlockWrapper";
import BenefitBlock from "../components/benefitBlock";
import { FaCheck } from "react-icons/fa";
import Columns from "../components/columns";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import Tile from "../components/Tile";
import Container from "../components/container";
import { Link } from "gatsby";
import * as styles from "./index.module.css";

export const query = graphql`
  {
    aura: file(relativePath: { eq: "logo-aura.png" }) {
      childImageSharp {
        gatsbyImageData(width: 500, layout: CONSTRAINED)
      }
    }
    synergius: file(relativePath: { eq: "Synergius-CRM-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 500, layout: CONSTRAINED)
      }
    }
    laptop: file(relativePath: { eq: "wsparcie-sprzedazy.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const CompanyManagement = ({ data }) => {
  return (
    <FunctionPageLayout
      title="Company management"
      headline="Comprehensive solutions for business"
    >
      <SEO
        title="Company management systems - take care of your business"
        description="Company management ✅ Dedicated and complementary to ERP solutions for large companies • Ready solutions with standard modules"
        keywords={["company management systems"]}
      />

      <p>
        The company management systems we offer were created as a result of{" "}
        <strong>
          experience gained during many years of implementing projects for
          business
        </strong>
        . In the field of comprehensive company management, we present two
        solutions. The first —{" "}
        <a href="https://aurabusiness.com/">Aura Business</a> — is a dedicated
        solution for large enterprises, niche industries or customers with
        non-standard needs that are not supported by other products available on
        the market. The second solution - Empireo - is a ready to use system
        with the necessary modules for business management. The undoubted
        advantage is the availability - the demo version can become a client's
        system in a few moments.
      </p>
      <br />
      <br />
      <Columns>
        <div>
          <h3>Dedicated solution for enterprises</h3>
          <p>
            <strong>Aura Business</strong> is the perfect solution for those
            companies that have non-standard needs. It is a flexible system
            supporting the entire enterprise. It can be integrated with any ERP
            system used in the company. One of the most important advantages of
            the system is its great flexibility. Aura Business consists of many
            modules, which are combined to provide comprehensive business
            support. However, these modules can also function separately and of
            course can be fully adapted to the needs of the company. The main
            modules include: CRM, E-commerce, Service Management.
          </p>
        </div>

        <div>
          <br />
          <br />
          <br />
          <br />
          <a href="https://aurabusiness.com/">
            <GatsbyImage
              image={data.aura.childImageSharp.gatsbyImageData}
              style={{
                width: "100%",
                margin: "0 auto",
                maxWidth: 300,
              }}
              alt="Aura Business"
            />
          </a>
          <Container>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <a className={styles.startNow} href="https://aurabusiness.com/">
                Learn more about Aura Business
              </a>
            </div>
          </Container>
        </div>
      </Columns>
      <br />
      <br />
      <center>
        <h4>
          Are you interested in our company management systems? Contact us. We'd
          be happy to talk about the development opportunities for your
          business.
        </h4>
        <Container>
          <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/contact/">
              Contact us
            </Link>
          </div>
        </Container>
      </center>
    </FunctionPageLayout>
  );
};

export default CompanyManagement;
